import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyDzq6d_9kxp1wZmkBJNpYkJOJyXj8-gkTg",
  authDomain: "hackathon-6d0f6.firebaseapp.com",
  projectId: "hackathon-6d0f6",
  storageBucket: "hackathon-6d0f6.appspot.com",
  messagingSenderId: "676081586948",
  appId: "1:676081586948:web:7419a54e63da239c01b651",
  measurementId: "G-VX8L4ZS7GR",
});

const db = firebase.firestore();
const auth = firebase.auth();

export { auth, db };
