import React, { useEffect, useState } from "react";
import { db } from "../firebaseConfig";
import { doc, setDoc, collection, getDocs } from "firebase/firestore";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

const FileManager = ({ projectUID, set_currentFile, set_showCodeEditor }) => {
  const [fileName, set_fileName] = useState("");
  const [activation, set_activation] = useState([]);
  const [showInput, set_showinput] = useState(false);
  const [files, set_files] = useState([]);
  const [loading, set_loading] = useState(false);

  const [prevIndex, set_prevIndex] = useState(0);

  const resetAll = () => {
    var _activation = [];
    for (var i = 0; i < 10000; i++) {
      _activation.push(false);
    }
    set_activation(_activation);
  };

  useEffect(() => {
    resetAll();
    const first_run = async () => {
      await db
        .collection("projects")
        .doc(projectUID)
        .collection("files")
        .onSnapshot((snapshot) => {
          set_files(
            snapshot.docs.map((doc) => {
              const data = doc.data();
              data.id = doc.id;
              return data;
            })
          );
        });
    };
    first_run();
  }, []);

  const createfile = async () => {
    const fileContents = {
      fileName: fileName,
      code: "",
    };
    await setDoc(
      doc(db, "projects", projectUID, "files", fileName),
      fileContents
    );
    set_showinput(false);
  };

  const changeCurrentFile = (index, fileName) => {
    resetAll();
    var _activation = activation;
    _activation[prevIndex] = false;
    _activation[index] = true;
    set_currentFile(fileName);
    set_activation(_activation);
    set_prevIndex(index);
    set_showCodeEditor(true);
  };

  return (
    <div className="w-full h-full bg-[#111111] flex flex-col">
      <div className="flex w-full   justify-between">
        <div>
          {showInput && !loading && (
            <input
              placeholder="Filename"
              className="outline-none text-white   bg-[#111111]  border-b-2 border-slate-700 ml-3 mt-3  tracking-wide"
              value={fileName}
              onChange={(e) => set_fileName(e.target.value)}
            />
          )}

          {showInput && !loading && (
            <button
              className="px-2 rounded-lg ml-3 mt-3  text-black text-xl    bg-[#818181]  "
              onClick={createfile}
            >
              +
            </button>
          )}
          {showInput && !loading && (
            <button
              className="px-2 rounded-lg ml-1 mt-3  text-red-500 text-xl    bg-none "
              onClick={() => set_showinput(false)}
            >
              x
            </button>
          )}
          {!showInput && !loading && (
            <button
              className="px-3 text-white w-full  mt-3 ml-3 text-slate-300 px-3 py-2 border-dashed border-2 border-slate-400  bg-[#242424] "
              onClick={() => set_showinput(true)}
            >
              + Create new file
            </button>
          )}
          {loading && <h1>Loading...</h1>}
        </div>
      </div>

      <div className="flex flex-col gap-3 px-3 pt-3 mt-5">
        {files.map((file, index) => {
          return (
            <div className="flex gap-1">
              <InsertDriveFileIcon sx={{ color: "#bce613", fontSize: 20 }} />
              {!activation[index] && (
                <button
                  className="bg-none text-white"
                  onClick={() => changeCurrentFile(index, file.fileName)}
                >
                  {file.fileName}
                </button>
              )}
              {activation[index] && (
                <button
                  className="text-white w-full text-left border-b-2 border-slate-400 "
                  onClick={() => changeCurrentFile(index, file.fileName)}
                >
                  {file.fileName}
                </button>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FileManager;
