import React, { useState } from "react";
import TopHeader from "../components/TopHeader";
import CodeEditor from "../components/CodeEditor";
import FileManager from "../components/FileManager";
import CodeHeader from "../components/CodeHeader";

const Editor = ({
  projectUID,
  set_showProjects,
  projectName,
  set_showProfile,
}) => {
  const [currentFile, set_currentFile] = useState("");
  const [showCodeEditor, set_showCodeEditor] = useState(false);

  return (
    <div className="w-screen h-screen flex flex-col">
      <CodeHeader
        showShare_external={true}
        showProfile={false}
        set_showProjects={set_showProjects}
        projectUID={projectUID}
        projectName={projectName}
        set_showProfile={set_showProfile}
      />
      <div className="flex-grow w-full flex">
        <div className="w-full h-full max-w-[300px]  ">
          <FileManager
            projectUID={projectUID}
            set_currentFile={set_currentFile}
            set_showCodeEditor={set_showCodeEditor}
          />
        </div>
        <div className="flex-grow h-full bg-[#252525] ">
          {showCodeEditor && (
            <CodeEditor
              projectUID={projectUID}
              currentFile={currentFile}
              set_showProfile={set_showProfile}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Editor;
