import React, { useEffect, useState } from "react";
import { doc, getDoc, setDoc } from "firebase/firestore";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import MarkdownView from "react-showdown";
import AceEditorComponent from "./AceEditorComponent";

import { db } from "../firebaseConfig";

const CodeEditor = ({ projectUID, currentFile }) => {
  const [code, set_code] = useState("");
  const [loading, set_loading] = useState(true);
  const [realtimeMode, set_realtimeMode] = useState(false);
  const [accessRights, set_accessRights] = useState([]);
  const [showAiCode, set_showAiCode] = useState(false);
  const [aiCode, set_aiCode] = useState("");
  const [consoleOut, setConsoleOut] = useState("");

  useEffect(() => {
    const firstRun = async () => {
      set_loading(true);
      await db
        .collection("projects")
        .doc(projectUID)
        .collection("files")
        .doc(currentFile)
        .onSnapshot((snapshot) => {
          console.log("Snapshot data");
          console.log(snapshot.data());
          set_code(snapshot.data().code);
        });
      // const projectRef = doc(db, "projects", projectUID);
      // set_accessRights()

      set_loading(false);
    };

    firstRun();
  }, [currentFile]);

  const savechanges = async ({ extension }) => {
    set_loading(true);
    await db
      .collection("projects")
      .doc(projectUID)
      .collection("files")
      .doc(currentFile)
      .update({
        code: code,
      });
    set_loading(false);
  };

  const handleChange = async (contents) => {
    set_code(contents);
    if (realtimeMode) {
      await db
        .collection("projects")
        .doc(projectUID)
        .collection("files")
        .doc(currentFile)
        .update({
          code: contents,
        });
    } else {
    }
  };

  async function makeRequest(prompt) {
    try {
      const response = await fetch(
        `https://codeai-k7wq.onrender.com/message?input=${encodeURIComponent(
          prompt
        )}`
      );
      const data = await response.json();
      console.log(data.message);
      return data.message;
    } catch (error) {
      console.error("Error making request:", error);
    }
  }

  const getAiCode = async () => {
    set_loading(true);
    const res = await makeRequest(code);
    set_showAiCode(true);
    console.log(res);
    set_aiCode(res);
    set_loading(false);
  };

  function copyDivToClipboard() {
    var range = document.createRange();
    range.selectNode(document.getElementById("markdownDiv"));
    window.getSelection().removeAllRanges(); // clear current selection
    window.getSelection().addRange(range); // to select text
    document.execCommand("copy");
    window.getSelection().removeAllRanges(); // to deselect
    alert("Copied to Clipboard.");
  }

  const pythonRun = () => {
    setConsoleOut("");
    const runner = new window.BrythonRunner({
      hangerUrl:
        "https://bd5f3cf5-9bd0-422e-ad9f-df6df97297fc-00-1p3v630vvcljn.sisko.replit.dev/hanger",
      stdout: {
        write(content) {
          setConsoleOut((prevVal) => prevVal + "\n" + content);
          console.log(content);
        },
        flush() {},
      },
      stderr: {
        write(content) {
          setConsoleOut((prevVal) => prevVal + "\n" + content);
          console.log(content);
        },
        flush() {},
      },
      stdin: {
        async readline() {
          var consoleContent = consoleOut;
          var lastLine = consoleContent.substr(
            consoleContent.lastIndexOf("\n") + 1
          );

          var userInput = prompt(lastLine);
          setConsoleOut(...(userInput + "\n"));
          return userInput;
        },
        flush() {},
      },
    });
    runner.runCode(code);
  };

  return (
    <div className="w-full flex flex-col bg-[#252525]   h-full">
      <div className="flex bg-[#252525] justify-between px-3">
        <h1 className="text-slate-400"> {currentFile} </h1>
        <div className="flex gap-3">
          <button onClick={getAiCode} className="bg-[#bce613] px-4 mr-3 ">
            <SmartToyIcon />
          </button>
          <button className="bg-green-500 px-4 mr-3 " onClick={pythonRun}>
            Run{" "}
          </button>
          <button
            className="bg-pink-500 text-slate-200 px-4 mr-3 "
            onClick={() => set_realtimeMode((prevVal) => !prevVal)}
          >
            {realtimeMode ? "Save Mode" : "RealTime Mode"}
          </button>

          <button className="bg-[#bce613] px-4 mr-3 " onClick={savechanges}>
            Save{" "}
          </button>
        </div>
      </div>

      <AceEditorComponent
        id="editor"
        mode="python"
        theme="github"
        value={code}
        className=" w-full flex-grow h-full bg-[#252525] outline-none text-white  px-3 py-3 tracking-widest font-thin "
        // onChange={(e) => set_code(e.target.value)  }
        // onChange={(e) => handleChange(e.target.value)}
        onChange={handleChange}
      />
      <div className="bg-[#232323] overflow-scroll border-t-2 border-slate-400 px-[47px] text-slate-400 w-full h-[200px] text-black  ">
        <p className="text-green-300 tracking-wide text-sm font-thin">
          OUTPUT:
        </p>
        <pre className="leading-3">{consoleOut}</pre>
      </div>
      {loading && (
        <div className="fixed top-0  flex items-center justify-center left-0  w-screen h-screen bg-slate-500  opacity-50">
          {loading && <h1 className="text-4xl font-black">Loading...</h1>}
        </div>
      )}
      {showAiCode && (
        <div className="top-0 left-0  fixed w-screen h-screen flex items-center bg-none justify-center">
          <div className="flex flex-col w-[700px]   bg-[#111111] text-white">
            <h1 className="text-center py-7 border-b-2 border-slate-400 ">
              {" "}
              <SmartToyIcon sx={{ fontSize: 40, color: "#bce613" }} />
            </h1>
            <MarkdownView
              id="markdownDiv"
              className="w-full h-[500px] p-3 text-[#bce613] overflow-scroll "
              markdown={aiCode}
              options={{ tables: true, emoji: true }}
            />
            <button
              onClick={copyDivToClipboard}
              className="bg-blue-700 px-3 py-2 text-center"
            >
              COPY
            </button>
            <button
              onClick={() => set_showAiCode(false)}
              className="bg-red-700 px-3 py-2 text-center "
            >
              CLOSE
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CodeEditor;
