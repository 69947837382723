import React from "react";
import CodeHeader from "../components/CodeHeader";

const Profile = ({ user, set_showProjects, set_showProfile }) => {
  return (
    <div className="w-screen h-screen flex flex-col  items-center bg-black">
      <CodeHeader
        showProfile={false}
        showShare_external={false}
        set_showProjects={set_showProjects}
        set_showProfile={set_showProfile}
      />
      <div className="w-full max-w-[700px] text-slate-400 mt-9">
        <h1> Name: {user.name}</h1>
        <h1> Email: {user.email}</h1>
      </div>
    </div>
  );
};

export default Profile;
