import React from 'react';
import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/theme-twilight';
import 'ace-builds/src-noconflict/mode-python';

const AceEditorComponent = ({ value, onChange }) => {
  return (
    <AceEditor
      mode="python"
      theme="twilight"
      value={value}
      onChange={onChange}
      fontSize={14}
      showPrintMargin={true}
      showGutter={true}
      highlightActiveLine={true}
      setOptions={{
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        enableSnippets: true,
        showLineNumbers: true,
        tabSize: 2,
      }}
      style={{ width: '100%', height: '100%'}}
    />
  );
};

export default AceEditorComponent;