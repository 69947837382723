import React, { useEffect, useState } from "react";
import ShareIcon from "@mui/icons-material/Share";
import HomeIcon from "@mui/icons-material/Home";
import {
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../firebaseConfig";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import Person2Icon from "@mui/icons-material/Person2";

const CodeHeader = ({
  projectUID,
  set_showProjects,
  showShare_external,
  projectName,
  set_showProfile,
  showProfile,
}) => {
  const [showShare, set_showShare] = useState(false);
  const [email, set_email] = useState("");
  const [accessRights, set_accessRights] = useState([]);
  const [RawVar, set_rawVar] = useState(false);
  const [addMode, set_addMode] = useState(true);

  // useEffect(() => {
  //   const firstRun = async () => {
  //     var _accessByUID;
  //     const projectRef = doc(db, "projects", projectUID);
  //     const projectSnap = await getDoc(projectRef);
  //     _accessByUID = projectSnap.data().accessedBy;
  //   };

  //   var _accessRights = [];
  //   for (var i = 0; i < _accessByUID.length; i++) {
  //     const projectRef = doc(db, "users", _accessByUID[i]);
  //     const projectSnap = getDoc(projectRef);
  //   }
  //   firstRun();
  // }, [RawVar]);

  const handleShareclick = async () => {
    var _matchedID = "";
    const q = query(collection(db, "users"), where("email", "==", email));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      _matchedID = doc.id;
    });

    const projectRef = doc(db, "projects", projectUID);
    const projectSnap = await getDoc(projectRef);
    const _startupName = projectSnap.data().name;
    await updateDoc(projectRef, {
      accessedBy: arrayUnion(projectUID),
    });

    let userRef = doc(db, "users", _matchedID);
    const sharedProjectDetails = {
      uid: projectUID,
      name: _startupName,
    };
    await updateDoc(userRef, {
      projectsAccessGranted: arrayUnion(sharedProjectDetails),
    });
    set_email("");
    alert("Project shared with user.");
    set_rawVar((prev) => !prev);
  };
  const showHme = () => {
    set_showProfile(false);
    set_showProjects(true);
  };

  return (
    <div className="w-full bg-[#111111] flex justify-between items-center">
      <div className="flex gap-1 items-center px-3 py-5">
        <h1
          onClick={showHme}
          className="text-xl flex items-center gap-2 text-slate-400 cursor-pointer"
        >
          <HomeIcon />
          Ayas Editor
        </h1>
        {showShare_external && (
          <h1 className="text-[#818181] ml-9 px-3 py-1 border-2 border-slate-700 ">
            {" "}
            {projectName}{" "}
          </h1>
        )}
      </div>
      {showProfile && (
        <button
          onClick={() => set_showProfile(true)}
          className="px-3 text-slate-300"
        >
          <Person2Icon sx={{ fontSize: 30 }} />
        </button>
      )}
      {showShare_external && (
        <div className="flex gap-5 px-3">
          <button
            onClick={() => set_showShare(true)}
            className="flex gap-1 items-center"
          >
            <div className="bg-[#818181] rounded-lg p-2 py-1">
              {" "}
              <GroupAddIcon sx={{ fontSize: 20 }} />{" "}
            </div>
            <h1 className="text-[#818181]  ">Share</h1>
          </button>
        </div>
      )}

      {/* Add User Dialog */}
      {showShare && (
        <div className="z-10 w-screen h-screen fixed top-0 left-0 bg-slate-400 opacity-80 flex items-center justify-center">
          <div className="bg-slate-700 w-full max-w-[500px] ">
            <div className="px-3 rounded-3xl flex items-center justify-between py-2 bg-slate-700 text-white font-bold border-b-2 border-slate-300 ">
              <h1 className="tracking-wide font-bold text-xl">Share</h1>
            </div>
            <div className="flex text-slate-300 pt-3 justify-betweenw-full">
              {addMode && (
                <button className="tracking-wide border-b-2 border-[#bce613] w-1/2 text-center ">
                  Share
                </button>
              )}
              {!addMode && (
                <button
                  onClick={() => set_addMode(true)}
                  className="tracking-wide  w-1/2 text-center"
                >
                  Share
                </button>
              )}
              {addMode && (
                <button
                  onClick={() => set_addMode(false)}
                  className="tracking-wide w-1/2 text-center "
                >
                  Users
                </button>
              )}
              {!addMode && (
                <button className="tracking-wide border-b-2 border-[#bce613] w-1/2 text-center ">
                  Users
                </button>
              )}
            </div>
            {addMode && (
              <div className=" w-full bg-slate-600 min-h-[300px]  rounded-3xl px-3 py-3 text-xl rounded-xl">
                <p className="text-slate-300 font-bold mt-9">
                  Email to share with:
                </p>
                <input
                  type="text"
                  className="outline-none bg-slate-600 border-slate-400 text-slate-400 border-b-2  font-bold tracking-wide w-full "
                  value={email}
                  onChange={(e) => set_email(e.target.value)}
                />
                <div className="w-full flex items-center mt-9  justify-center">
                  <button
                    onClick={handleShareclick}
                    className="bg-slate-400 px-3 py-1 rounded-3xl   font-bold"
                  >
                    SHARE
                  </button>
                </div>
                <div className="w-full flex items-center mt-3  justify-center">
                  <button
                    onClick={() => set_showShare(false)}
                    className="bg-slate-600 border-2 text-slate-400 border-slate-400 px-3 py-1 rounded-3xl   font-bold"
                  >
                    CLOSE
                  </button>
                </div>
              </div>
            )}
            {!addMode && (
              <div className="w-full bg-slate-600 min-h-[300px]  rounded-3xl px-3 py-3 text-xl rounded-xl">
                {accessRights.map((_rights) => {
                  return (
                    <button className="w-full text-[#bce613] tracking-wide bg-[#111111]  py-6 ">
                      {_rights}
                    </button>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CodeHeader;
