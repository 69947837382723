import Editor from "./pages/Editor";

import AuthPage from "./pages/AuthPage";
import { useState } from "react";
import Projects from "./pages/Projects";
import Profile from "./pages/Profile";

function App() {
  const [logged, set_logged] = useState(false);
  const [user, set_user] = useState({});
  const [projectUID, set_projectUID] = useState("");
  const [showProjects, set_showProjects] = useState(false);
  const [projectName, set_projectName] = useState("");
  const [showprofile, set_showProfile] = useState(false);

  return (
    <div className="App">
      {!logged && !showProjects && !showprofile && (
        <AuthPage
          set_logged={set_logged}
          set_user={set_user}
          set_showProjects={set_showProjects}
        />
      )}
      {logged && showProjects && !showprofile && (
        <Projects
          user={user}
          set_showProjects={set_showProjects}
          set_projectUID={set_projectUID}
          set_projectName={set_projectName}
          set_showProfile={set_showProfile}
        />
      )}
      {logged && !showProjects && !showprofile && (
        <Editor
          projectName={projectName}
          projectUID={projectUID}
          set_showProjects={set_showProjects}
          set_showProfile={set_showProfile}
        />
      )}
      {logged && showprofile && (
        <Profile
          set_showProjects={set_showProjects}
          user={user}
          set_showProfile={set_showProfile}
        />
      )}
    </div>
  );
}

export default App;
