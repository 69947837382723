import GoogleButton from "react-google-button";
import {
  signInWithPopup,
  getAdditionalUserInfo,
  GoogleAuthProvider,
} from "firebase/auth";
import { useState } from "react";
import { auth, db } from "../firebaseConfig";
import { doc, onSnapshot, setDoc } from "firebase/firestore";
import { CircularProgress } from "@mui/material";

const AuthPage = ({ set_logged, set_user, set_showProjects }) => {
  const provider = new GoogleAuthProvider();
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    await signInWithPopup(auth, provider)
      .then((result) => {
        // IS A NEW USER
        if (getAdditionalUserInfo(result).isNewUser) {
          const newUserData = {
            name: result.user.displayName,
            email: result.user.email,
            uid: result.user.uid,
            projectsCreated: [],
            projectsAccessGranted: [],
          };
          set_user(newUserData);
          setDoc(doc(db, "users", result.user.uid), newUserData);
          set_logged(true);
          set_showProjects(true);
          setLoading(false);
        }

        // NOT A NEW USER
        if (!getAdditionalUserInfo(result).isNewUser) {
          onSnapshot(doc(db, "users", result.user.uid), (snap) => {
            set_user(snap.data());
            set_logged(true);
            set_showProjects(true);
            setLoading(false);
          });
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <div className="">
      <div className="flex flex-col h-screen">
        <div className="text-[#bce613] text-center text-3xl h-2/3  w-screen flex  items-center justify-center bg-black">
          <div className="w-full h-full flex flex-col items-center justify-center">
            <h1 className="text-5xl font-black">Ayas Editor</h1>
            <br />
            <h2 className="text-5xl font-black">
              Updating... <br /> <br />
              Please hold for 12 hours...{" "}
            </h2>
          </div>
        </div>
        <div className="flex items-center justify-center  w-screen h-1/3 bg-[#fff] ">
          {/* <div className="">
            {!loading && (
              <GoogleButton onClick={handleClick}>
                {" "}
                Continue with Google{" "}
              </GoogleButton>
            )}
            {loading && <CircularProgress />}
          </div> */}
        </div>
      </div>
      {/* {logged && <ProjectsPage user={user} />} */}
    </div>
  );
};

export default AuthPage;
