import React, { useEffect, useState } from "react";
import { db } from "../firebaseConfig";
import {
  doc,
  setDoc,
  collection,
  getDocs,
  addDoc,
  getDoc,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";
import { CircularProgress } from "@mui/material";
import CodeHeader from "../components/CodeHeader";

const Projects = ({
  user,
  set_projectUID,
  set_showProjects,
  set_projectName,
  set_showProfile,
}) => {
  const [showcreateProject, set_showcreateProject] = useState(false);
  const [name, set_name] = useState("");
  const [loading, set_loading] = useState(false);
  const [projectsfetched, set_projectsFetched] = useState([]);
  const [createdMode, set_createdmode] = useState(true);

  useEffect(() => {
    const firstRun = async () => {
      set_loading(true);
      var _projects = [];
      if (createdMode) {
        const userRef = doc(db, "users", user.uid);
        const userSnap = await getDoc(userRef);
        set_projectsFetched(userSnap.data().projectsCreated);
      } else {
        const userRef = doc(db, "users", user.uid);
        const userSnap = await getDoc(userRef);
        set_projectsFetched(userSnap.data().projectsAccessGranted);
      }

      set_loading(false);
    };
    firstRun();
  }, [loading, createdMode]);

  const handleCreateProject = async () => {
    set_loading(true);
    var _createdProjectID = "";
    const projectOps = await addDoc(collection(db, "projects"), {
      name: name,
      uid: "xxx",
      admin: user.uid,
      accessedBy: [],
    }).then((docRef) => {
      _createdProjectID = docRef.id;
    });

    let projectRef = doc(db, "projects", _createdProjectID);
    await updateDoc(projectRef, {
      uid: _createdProjectID,
    });

    let userRef = doc(db, "users", user.uid);
    const newProjectDetails = {
      uid: _createdProjectID,
      name: name,
    };
    await updateDoc(userRef, {
      projectsCreated: arrayUnion(newProjectDetails),
    });

    set_loading(false);
    alert("Project Created !");
    set_name("");
  };

  const openProject = (_projectUID, _projectname) => {
    set_projectUID(_projectUID);
    set_projectName(_projectname);
    set_showProjects(false);
  };

  return (
    <div className="w-screen h-screen bg-[#252525] flex flex-col">
      <CodeHeader
        showProfile={true}
        showShare_external={false}
        set_showProfile={set_showProfile}
        set_showProjects={set_showProjects}
      />
      <div className="w-full flex flex-col flex-grow items-center bg-[#252525] ">
        <div className="px-3 w-full max-w-[700px] flex mt-3 items-enter  py-3  text-slate-200 ">
          <h1 className="tracking-wide text-xl">Projects</h1>
          {!showcreateProject && (
            <button
              className="px-2 rounded-lg ml-3  text-black text-xl    bg-[#818181]  "
              onClick={() => set_showcreateProject(true)}
            >
              +
            </button>
          )}
        </div>
        {!showcreateProject && (
          <div className="flex w-full justify-center">
            <div className="w-full max-w-[700px]  py-2 pt-4 text-slate-200  flex justify-between ">
              {createdMode && (
                <button className="tracking-wide border-b-2 border-[#bce613] w-1/2 text-center ">
                  Created
                </button>
              )}
              {!createdMode && (
                <button
                  onClick={() => set_createdmode(true)}
                  className="tracking-wide  w-1/2 text-center"
                >
                  Created
                </button>
              )}
              {createdMode && (
                <button
                  onClick={() => set_createdmode(false)}
                  className="tracking-wide w-1/2 text-center "
                >
                  Shared
                </button>
              )}
              {!createdMode && (
                <button className="tracking-wide border-b-2 border-[#bce613]  w-1/2 text-center">
                  Shared
                </button>
              )}
            </div>
          </div>
        )}
        {!showcreateProject && (
          <div className="w-full  h-full justify-center flex mt-9  ">
            <div class="flex flex-col gap-3 h-screen  w-full max-w-[700px]">
              {projectsfetched.map((_project, index) => {
                return (
                  <button
                    onClick={() => openProject(_project.uid, _project.name)}
                    className="w-full text-[#bce613] tracking-wide bg-[#111111]  py-6 "
                  >
                    {_project.name}
                  </button>
                );
              })}
            </div>
          </div>
        )}

        {showcreateProject && (
          <div className="w-full flex justify-center flex-grow">
            <div className="w-full max-w-[700px]  rounded-3xl px-3 py-3 text-xl rounded-xl">
              <p className="text-slate-300 font-bold mt-9">Name:</p>
              <input
                type="text"
                className="outline-none bg-[#252525]   border-slate-400 text-slate-400 border-b-2  font-bold tracking-wide w-full "
                value={name}
                onChange={(e) => set_name(e.target.value)}
              />
              <div className="w-full flex items-center mt-9  justify-center">
                <button
                  onClick={handleCreateProject}
                  className="bg-slate-400 px-3 py-1 rounded-3xl   font-bold"
                >
                  CREATE
                </button>
              </div>
              <div className="w-full flex items-center mt-3  justify-center">
                <button
                  onClick={() => set_showcreateProject(false)}
                  className="bg-slate-600 border-2 text-slate-400 border-slate-400 px-3 py-1 rounded-3xl   font-bold"
                >
                  CANCEL
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Projects;
